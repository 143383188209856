<script>
export default {
	props: {
		msg: {
			type: String,
			required: false,
			default: 'Volver al inicio'
		},
		customClick: {
			type: Boolean,
			required: false,
			default: false
		}	
	},
	methods: {
		click() {
			this.$emit('click');
		},
		goBack() {
			const currentRoute = this.$router.history.current.fullPath;
			const targetRoute = this.$router.history.current.matched[0].path;
			
			if (currentRoute === targetRoute) {
			// Si la ruta actual es la misma que la ruta objetivo, recarga la página
			this.$router.go(this.$router.currentRoute)
			} else {
			// Navega a la ruta objetivo
			this.$router.replace(targetRoute).catch(err => {
				if (err.name !== 'NavigationDuplicated') {
				console.error(err);
				}
			});
			}
		}
	}
}
</script>
<template>
	<div class="card border-0">
		<div class="card-body d-flex justify-content-center">
			<h5 class="d-flex flex-column align-items-center text-secondary font-700">
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100" height="100" viewBox="0 0 188 187">
					<defs>
						<linearGradient id="linear-gradient" x1="0.195" y1="0.736" x2="1.032" y2="0.096"
							gradientUnits="objectBoundingBox">
							<stop offset="0" stop-color="#ffd900" />
							<stop offset="1" stop-color="#ffb300" />
						</linearGradient>
						<linearGradient id="linear-gradient-2" x1="0.901" y1="0.075" x2="0.124" y2="0.86"
							gradientUnits="objectBoundingBox">
							<stop offset="0" stop-color="#fff" />
							<stop offset="1" stop-color="#fff" stop-opacity="0" />
						</linearGradient>
						<radialGradient id="radial-gradient" cx="0.5" cy="0.5" r="0.5"
							gradientUnits="objectBoundingBox">
							<stop offset="0.001" stop-color="#ff7b00" stop-opacity="0.302" />
							<stop offset="1" stop-color="#ff7b00" stop-opacity="0" />
						</radialGradient>
						<clipPath id="clip-path">
							<path id="Trazado_7767" data-name="Trazado 7767"
								d="M230.642,412.234a.857.857,0,0,0,.313,1.685,19.007,19.007,0,0,1,3.459-.32,20.617,20.617,0,0,1,8.5,1.913.856.856,0,0,0,.7-1.561,22.314,22.314,0,0,0-9.207-2.066,20.752,20.752,0,0,0-3.774.349"
								transform="translate(-229.942 -411.885)" fill="#3d3d3d" />
						</clipPath>
						<clipPath id="clip-path-2">
							<rect id="Rectángulo_4445" data-name="Rectángulo 4445" width="27.198" height="25.125"
								fill="#ffd900" />
						</clipPath>
						<clipPath id="clip-path-3">
							<rect id="Rectángulo_4445-2" data-name="Rectángulo 4445" width="27.198" height="25.125"
								fill="url(#linear-gradient)" />
						</clipPath>
					</defs>
					<g id="Grupo_15372" data-name="Grupo 15372" transform="translate(20.622 18.5)">
						<g transform="matrix(1, 0, 0, 1, -20.62, -18.5)" filter="url(#Elipse_577)">
							<ellipse id="Elipse_577-2" data-name="Elipse 577" cx="71.5" cy="71" rx="71.5" ry="71"
								transform="translate(20.5 18.5)" fill="#fff" />
						</g>
						<g id="Grupo_13890" data-name="Grupo 13890" transform="translate(12.552 12.55)">
							<ellipse id="Elipse_166" data-name="Elipse 166" cx="58.5" cy="58" rx="58.5" ry="58"
								transform="translate(0.327 0.449)" class="color-main" opacity="0.2" />
							<ellipse id="Elipse_167" data-name="Elipse 167" cx="43.5" cy="43" rx="43.5" ry="43"
								transform="translate(15.327 15.449)" class="color-main" />
							<g id="Grupo_13887" data-name="Grupo 13887" transform="translate(31.543 32.011)">
								<g id="Grupo_13884" data-name="Grupo 13884" transform="translate(0 0)">
									<g id="Grupo_10169" data-name="Grupo 10169" transform="translate(0 0)">
										<circle id="Elipse_169" data-name="Elipse 169" cx="27.397" cy="27.397"
											r="27.397" fill="#f6c700" opacity="0.45" />
										<circle id="Elipse_520" data-name="Elipse 520" cx="27.397" cy="27.397"
											r="27.397" transform="translate(0)" opacity="0.45"
											fill="url(#linear-gradient-2)" />
										<circle id="Elipse_168" data-name="Elipse 168" cx="27.397" cy="27.397"
											r="27.397" transform="translate(0)" fill="#ffd900" />
										<circle id="Elipse_171" data-name="Elipse 171" cx="5.097" cy="5.097" r="5.097"
											transform="translate(34.647 23.223)" fill="url(#radial-gradient)" />
										<circle id="Elipse_172" data-name="Elipse 172" cx="5.097" cy="5.097" r="5.097"
											transform="translate(9.862 23.223)" fill="url(#radial-gradient)" />
									</g>
									<g id="Grupo_13885" data-name="Grupo 13885" transform="translate(12.17 13.337)">
										<g id="Grupo_13880" data-name="Grupo 13880" transform="translate(0)">
											<g id="Grupo_13875" data-name="Grupo 13875"
												transform="translate(7.322 21.29)">
												<g id="Grupo_13874" data-name="Grupo 13874" clip-path="url(#clip-path)">
													<rect id="Rectángulo_4444" data-name="Rectángulo 4444"
														width="14.374" height="3.703" transform="translate(-0.071)"
														fill="#3d3d3d" />
												</g>
											</g>
											<g id="Grupo_13877" data-name="Grupo 13877">
												<g id="Grupo_13881" data-name="Grupo 13881">
													<path id="Trazado_7768" data-name="Trazado 7768"
														d="M458.387,130.543a2.227,2.227,0,0,1-.266-.016,2.633,2.633,0,0,1-1.968-1.452,4.57,4.57,0,0,1-.461-2.689c.234-1.983,1.549-3.478,3.06-3.478a2.226,2.226,0,0,1,.266.016,2.633,2.633,0,0,1,1.968,1.452,4.571,4.571,0,0,1,.46,2.689c-.234,1.983-1.549,3.478-3.06,3.478"
														transform="translate(-436.667 -116.555)" fill="#3d3d3d" />
													<path id="Trazado_7769" data-name="Trazado 7769"
														d="M177.559,102.349a2.3,2.3,0,0,1-.449-.045c-1.593-.322-2.508-2.222-2.084-4.326.377-1.868,1.7-3.224,3.148-3.224a2.29,2.29,0,0,1,.449.045c1.593.322,2.508,2.222,2.084,4.326-.377,1.868-1.7,3.225-3.148,3.225"
														transform="translate(-170.444 -89.856)" fill="#3d3d3d" />
													<path id="Trazado_7770" data-name="Trazado 7770"
														d="M468.341,60.689a.863.863,0,0,1-.833-1.089.85.85,0,0,1,.491-.565,12.974,12.974,0,0,1,4.645-.748c1.751,0,4.085.277,4.9,1.06a.564.564,0,0,1,.133.619.57.57,0,0,1-.53.359h-.032l-.049-.006a25.471,25.471,0,0,0-3.487-.221,17.837,17.837,0,0,0-4.946.541.865.865,0,0,1-.292.05"
														transform="translate(-447.878 -55.274)" fill="#3d3d3d" />
													<path id="Trazado_7771" data-name="Trazado 7771"
														d="M89.058,4.077a.78.78,0,0,1-.645-1.216A6.187,6.187,0,0,1,93.68,0a7.325,7.325,0,0,1,5.468,2.5A.749.749,0,0,1,98.1,3.563a6.631,6.631,0,0,0-4.227-1.468A5.517,5.517,0,0,0,89.653,3.8a.788.788,0,0,1-.6.272"
														transform="translate(-88.28)" fill="#3d3d3d" />
												</g>
											</g>
										</g>
									</g>
								</g>
								<g id="Grupo_13883" data-name="Grupo 13883" transform="translate(3.174 33.292)">
									<g id="Grupo_13882" data-name="Grupo 13882" clip-path="url(#clip-path-2)">
										<path id="Trazado_7772" data-name="Trazado 7772"
											d="M2.758,22.319C-2.924,16.773,1.864,11,2.281,6.649A35.112,35.112,0,0,0,2.6,1.475C2.547.154,3.7-.016,4.31,0S5.944,1.116,6.043,3.052s0,5.379.992,5.911,3.55.816,8.442-.271,8.484-2.206,9.617-2.416A1.615,1.615,0,0,1,27.13,7.491,1.555,1.555,0,0,1,26.11,9.4c-.758.286-7.407,2.776-7.964,2.975a.293.293,0,0,0-.094.548,2.292,2.292,0,0,1,1.122,1.938c-.02,1.206-.845,1.472-.752,2.094.073.481.724.529.752,1.751s-.907,1.247-1.017,1.839.64.526.253,1.817c-.472,1.571-3.343,2.548-6.677,2.734-3.048.17-6.674-.537-8.974-2.782"
											transform="translate(0.001 0)" fill="#ffd900" />
									</g>
								</g>
								<g id="Grupo_13886" data-name="Grupo 13886" transform="translate(3.174 33.292)">
									<g id="Grupo_13882-2" data-name="Grupo 13882" clip-path="url(#clip-path-3)">
										<path id="Trazado_7772-2" data-name="Trazado 7772"
											d="M2.758,22.319C-2.924,16.773,1.864,11,2.281,6.649A35.112,35.112,0,0,0,2.6,1.475C2.547.154,3.7-.016,4.31,0S5.944,1.116,6.043,3.052s0,5.379.992,5.911,3.55.816,8.442-.271,8.484-2.206,9.617-2.416A1.615,1.615,0,0,1,27.13,7.491,1.555,1.555,0,0,1,26.11,9.4c-.758.286-7.407,2.776-7.964,2.975a.293.293,0,0,0-.094.548,2.292,2.292,0,0,1,1.122,1.938c-.02,1.206-.845,1.472-.752,2.094.073.481.724.529.752,1.751s-.907,1.247-1.017,1.839.64.526.253,1.817c-.472,1.571-3.343,2.548-6.677,2.734-3.048.17-6.674-.537-8.974-2.782"
											transform="translate(0.001 0)" fill="url(#linear-gradient)" />
									</g>
								</g>
							</g>
						</g>
					</g>
				</svg>
				Sin resultados para mostrar
				<button @click="customClick? click() :goBack()" class="btn btn-custom btn-custom-color-blue rounded-4 font-700 mt-2">
					{{msg}}
				</button>
			</h5>
		</div>
	</div>
</template>