<script>
	export default {
		props: {
			initialDate: {
				type: Date,
				required: false,
				default: null
			},
			min: {
				type: Date,
				required: false,
				default: null
			},
			max: {
				type: Date,
				required: false,
				default: null
			},
			requiredField: {
				type: Boolean,
				required: false,
				default: false
			},
			formError: {
				type: Boolean,
				required: false,
				default: false
			},
			placeHolder: {
				type: String,
				required: false,
				default: "00/00/0000"
			},
			idElement: {
				type: String,
				required: false,
				default: null
			}
		},
		data() {
			return {
				date: this.initialDate,
				valid: false,
				popover: {
					visibility: "focus"
				}
			}
		},
		watch: {
			initialDate(value) {
				this.date = value;
			},
			date(value) {
				if(value) this.valid = true;
				else this.valid = false;
				this.$emit("dateEmit", value);
			}
		}
	}
</script>

<template>
	<v-date-picker 
		locale="es"
		v-model="date" 
		class="lw-date-picker"
		:min-date="min"
		:max-date="max"
		:popover="popover"
	>
		<template v-slot="{ inputValue, inputEvents }">
			<input
				:id="idElement"
				class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
				:class="{'is-invalid': !valid && requiredField && formError }"
				:value="inputValue"
				v-on="inputEvents"
				:placeholder="placeHolder"
			/>
			<i class="fa-regular fa-calendar lw-date-picker-icon"></i>
		</template>
	</v-date-picker>
</template>
<style lang="scss">
@import "~@/assets/scss/abstracts/_variables.scss";

.lw-date-picker {
	position: relative;
	input {
		height: 48px;
		width: 100%;
		color: lighten($black, 40%);
	}
	&-icon {
		position: absolute;
		top: 0;
		right: 10px;
		color: $blue-color;
	}
}
</style>