<template>
   <div class="tabs-custom d-flex align-content-between flex-wrap ps-0">
      <a
         href="javascript:"
         v-for="(tab, index) in tabs"
         :key="index"
         class="tabs-custom-tab"
         @click="goTo(tab.route, tab)"
         :class="activeTab(tab)"
      >
         <span>{{ tab.name }}</span>
      </a>
      <Question
         v-if="open_question_modal"
         :msg="question_modal_msg"
         :hideCancel="false"
         @cancel="cancelQuestion"
         @accept="acceptQuestion"
      />
   </div>
</template>

<script>
import Question from "../Modales/Question.vue";

export default {
   components: { Question },
   props: {
      tabs: { require: true,  },
   },
   data() {
      return {
         open_question_modal: false,
         question_modal_msg:
            "¿Realmente quieres salir? <br/> Se perderán los cambios realizados.",
         route_selected: null,
      };
   },
   methods: {
      goTo(route, tab) {
         var current_route = this.$route.name;
         if (route == current_route) {
            if (tab.id != undefined) {
               if (this.$route.params.id != tab.id) {
                  this.$router.push({
                     name: route,
                     params: { id: tab.id },
                  });
               }
            }
            return;
         } else {
            if (current_route.includes("edit")||current_route.includes("news-add")||current_route.includes("usuarios-add")) {
               this.open_question_modal = true;
               this.route_selected = route;
            } else if (tab.id != undefined) {
               this.$router.push({
                  name: route,
                  params: { id: tab.id },
               });
            } else if (current_route == "gallery-add") {
               this.open_question_modal = true;
               this.route_selected = route;
            } else {
               this.$router.push({ name: route });
            }
         }
      },
      activeTab(this_tab) {
         var tab = this_tab.route;
         var current_route = this.$route.name;
         if (tab == current_route) {
            if (this.$route.params.id != undefined) {
               if(tab == "solici-request"){
                  if(this_tab.id == this.$route.params.id) return "active"
                  else return "";
               }
               else{
                  return "active"
               }
            } else {
               return "active";
            }
         } else if (tab == "gallery-add" && current_route == "gallery-edit") {
            return "active";
         } else if (tab == "muro-add" && current_route == "muro-edit") {
            return "active";
         } else if (tab == "usuarios-add" && current_route == "usuarios-edit") {
            return "active";
         } else if (tab == "noticias-add" && current_route == "noticias-edit") {
            return "active";
         } else if (tab == "seguridad-add" && current_route == "seguridad-edit") 
         { return "active";
         } else if (tab == "culture-valor-add" && current_route == "culture-valor-edit") { 
          return "active";
         } else if (tab== "encuestas-add" && current_route == "encuestas-edit") {
           return "active";
         } else if (tab == "push-add" && current_route == "push-edit") {
          return "active";
         } else if (this_tab.name == "Personalización" && this.$route.path.includes("customization")) {
          return "active";
         }
      },
      cancelQuestion() {
         this.open_question_modal = false;
      },
      acceptQuestion() {
         this.open_question_modal = false;
         this.$router.push({ name: this.route_selected });
      },
   },
};
</script>